














































































































































































































































import MutableInputContainer from '@/components/inputs/MutableInputContainer.vue';
import StateButton from '@/components/inputs/StateButton.vue';
import { DecoContestSubmission } from '@/game/infos/dataServerPackets';
import { decodeDecoSet, DecoSet } from '@/game/infos/decorativeInfos';
import { FishType } from '@/game/infos/fishInfos';
import { ServerTime } from '@/store/api/serverTime';
import { Global } from '@/store/globalz';
import { EditState } from '@/store/models.def';
import globalx from '@/store/modules/globalx';
import userx from '@/store/modules/userx';
import fb from '@/store/sf-firestore';
import { Queuer } from '@/util/queuer';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DecoEditor from '../mainMenu/tools/decoEditor/DecoEditor.vue';
import DecoItemInventoryModal from '../mainMenu/tools/inventory/DecoItemInventoryModal.vue';
import DecoSkinInventoryModal from '../mainMenu/tools/inventory/DecoSkinInventoryModal.vue';
import FishInventoryModal from '../mainMenu/tools/inventory/FishInventoryModal.vue';
import { generateLargeFishImage } from '@/components/ui/mainMenu/tools/largeFishImageGenerator';
import assetx from '@/store/modules/assetx';
import { eventTimes, TimeLimitedQuestCode } from '@/game/infos/questTimeLimited';

@Component({
	components: {
		DecoSkinInventoryModal,
		DecoEditor,
		DecoItemInventoryModal,
		FishInventoryModal,
		StateButton,
		MutableInputContainer,
	},
})
export default class DecoContest extends Vue {
	public get contestOrdinal() {
		return globalx.decoContestOrdinal;
	}
	public get expired() {
		return this.time > this.deadline;
	}
	public get deadDate() {
		const dates = new Date(this.deadline).toString().split(' ');
		return [dates[2], dates[1], dates[3], dates[4]].join(' ');
	}
	public get submitted() {
		return this.contestSubmission && this.contestSubmission.fishType;
	}

	public get date1() {
		const dd = new Date(eventTimes[TimeLimitedQuestCode.DecoSubmission][1]);
		return `${new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit',
		}).format(dd)}`.replace(',', ', at');
	}
	public get date2() {
		const dd = new Date(eventTimes[TimeLimitedQuestCode.DecoVote][1]);
		return `${new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
			hour12: true,
			hour: '2-digit',
			minute: '2-digit',
		}).format(dd)}`.replace(',', ', at');
	}
	public get date3() {
		const dd = new Date(eventTimes[TimeLimitedQuestCode.DecoWait][1] + 1);
		return `${new Intl.DateTimeFormat(
			'en-UK', {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		}).format(dd)}`.replace(',', ', at');
	}

	public get timeLeft() {
		if (this.expired) {
			return 'Submission Closed!';
		}
		const closeTime = this.deadline;
		let tl = closeTime - this.time;
		if (tl < 0) {
			tl = 0;
		}
		const day = Math.floor(tl / (24 * 60 * 60 * 1000));
		tl -= day * 24 * 60 * 60000;
		const hour = Math.floor(tl / (60 * 60 * 1000));
		tl -= hour * 60 * 60000;
		const min = Math.round(tl / 60000);
		// tl -= min * 60000
		return day > 0 ? `${day}d ${hour}h left` : `${hour}h ${min}m left`;
	}
	public get submitLabel() {
		const labels = {
			active: this.submitted ? 'Resubmit' : 'Submit',
			sending: 'Submitting',
			done: 'Resubmit',
		};
		return labels[this.submitState];
	}
	public get userName() {
		return this.contestSubmission?.discordId || '';
	}
	public get validateDisplayName(): boolean | null {
		if (!this.validateDisplayNameNow) {
			return null;
		}
		if (this.displayNameInput === '') {
			return true;
		}
		return this.displayNameInput.match(/^.{3,32}#[0-9]{4}$/) ? true : false;
	}

	public contestSubmission: Partial<DecoContestSubmission> | null = null;
	public selectedFish: FishType = FishType.BlueShark;
	public currentPage = 'landing';
	public get time() {
		return globalx.time;
	}

	public imgSrc = '';
	public get deadline() {
		return eventTimes[TimeLimitedQuestCode.DecoSubmission][1];
	}

	public submitState: EditState = 'active';

	public displayNameInput: string = '';
	public displayNameEditState: EditState = 'done';
	public validateDisplayNameNow = false;
	@Watch('contestSubmission', { immediate: true })
	public async sumcheckChange(newVal: Partial<DecoContestSubmission> | null) {
		if (!newVal || !newVal.fishType) {
			this.imgSrc = '';
			return;
		}
		const { fishType, decoSet } = newVal;
		const skinGroup = newVal.skin || 0;
		await Queuer.queue('fishImage', async () => {
			this.imgSrc = await generateLargeFishImage(
				fishType,
				skinGroup,
				decodeDecoSet(decoSet || ''),
			);
		});
	}

	public async submitFish(fishType: FishType) {
		if (this.expired) {
			return;
		}
		this.submitState = 'sending';
		try {
			const response = await fb.decoContest(globalx.decoContest, { fishType });
			this.contestSubmission = this.contestSubmission
				? { ...this.contestSubmission, ...response }
				: response;
			if (response.skin === undefined) {
				this.contestSubmission.skin = undefined;
			}
			if (response.decoSet === undefined) {
				this.contestSubmission.decoSet = undefined;
			}
		} catch (error) {
			//   this.contestSubmission = null;
			this.showError((error as any).message);
		}
		this.submitState = 'active';
	}

	public showError(error: any) {
		this.$root.$emit('error', error);
	}
	public async onToFishSelector() {
		const result = await Global.fishInvetoryModal.show(
			this.submitted ? this.contestSubmission!.fishType! : this.selectedFish,
			2,
		);
		if (result !== null) {
			this.selectedFish = result;
			const value = await this.$bvModal.msgBoxConfirm(
				'By submitting you acknowledge that you have read and agreed the rules and regulations of this contest. Do you want to proceed?',
				{
					title: `Submit Entry`,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'primary',
					cancelVariant: 'link btn-link-warning',
					modalClass: 'funny-modal',
					okTitle: 'Confirm',
					cancelTitle: 'Cancel',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				},
			);
			if (!value) {
				return;
			}
			this.submitFish(result);
		}
	}

	public showFishes() {
		this.currentPage = 'landing';
		this.onToFishSelector();
	}
	public onCustomize(type: FishType) {
		this.selectedFish = type;
		this.currentPage = 'decoEditor';
	}

	@Watch('currentPage', { immediate: true })
	public onPageChange(value: string) {
		this.app.page = value === 'decoEditor' ? 'decoEditor' : 'none';
	}

	@Watch('displayNameEditState')
	public onPathEditStateChanged(val: EditState, oldVal: EditState) {
		if (val === 'active' && oldVal !== 'sending') {
			this.displayNameInput = this.userName || '';
		}
	}

	public async submitDisplayName() {
		if (this.expired) {
			return;
		}
		this.validateDisplayNameNow = true;
		this.displayNameInput = this.displayNameInput.replace('\\', '/');
		if (!this.validateDisplayName) {
			await this.$nextTick();
			this.displayNameEditState = 'active';
			return;
		}
		try {
			this.displayNameEditState = 'sending';
			const response = await fb.decoContest(globalx.decoContest, {
				discordId: this.displayNameInput,
			});
			this.contestSubmission = this.contestSubmission
				? { ...this.contestSubmission, ...response }
				: response;
			this.validateDisplayNameNow = false;
			this.displayNameEditState = 'done';
		} catch (error) {
			this.displayNameEditState = 'active';
			// show error modal
			this.showError((error as any).message);
		}
	}
	public async mounted() {
		this.loadContestSumbission();
		this.$root.$on('customize', this.onCustomize);
		(this.$refs.stage as HTMLDivElement).appendChild(this.app.view);
		this.app.page = 'none';
	}
	public beforeDestroy() {
		this.$root.$off('customize', this.onCustomize);
		(this.$refs.stage as HTMLDivElement).removeChild(this.app.view);
		if (this.app.page === 'decoEditor') {
			this.app.page = 'none';
		}
	}

	public async loadContestSumbission() {
		const lobbyServerAddress = globalx.lobbyServerAddress;
		try {
			const response = await fetch(
				`${lobbyServerAddress}/decoContest/${globalx.decoContest}/${userx.uid}`,
			);
			if (!response.ok) {
				throw await response.json();
			}
			const json = (await response.json()) as Partial<
				DecoContestSubmission
			> | null;
			this.contestSubmission = json;
		} catch (error) {
			this.contestSubmission = null;
		}
	}

	public get app() {
		return assetx.mainApp;
	}
}
